// https://github.com/alexcrack/angular-ui-notification
angular.module("app")
    .config(function (NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 7500, // The time in ms the message is showing before start fading out
            startTop: 10, // Vertical padding between messages and vertical border of the browser
            startRight: 10, // Horizontal padding between messages and horizontal border of the browser
            verticalSpacing: 10, // Vertical spacing between messages
            horizontalSpacing: 10, // Horizontal spacing between messages
            positionX: 'right', // Horizontal position of the message
            positionY: 'bottom', // Vertical position of the message
            replaceMessage: false // If true every next appearing message replace old messages
        });
    });